import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _916ccca4 = () => interopDefault(import('../docs/pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _1d3ea704 = () => interopDefault(import('../docs/pages/appbars/index.vue' /* webpackChunkName: "pages/appbars/index" */))
const _66af7c61 = () => interopDefault(import('../docs/pages/buttons.vue' /* webpackChunkName: "pages/buttons" */))
const _6fbfe780 = () => interopDefault(import('../docs/pages/colors.vue' /* webpackChunkName: "pages/colors" */))
const _642b16fe = () => interopDefault(import('../docs/pages/errors/index.vue' /* webpackChunkName: "pages/errors/index" */))
const _8c6cebc4 = () => interopDefault(import('../docs/pages/filters/index.vue' /* webpackChunkName: "pages/filters/index" */))
const _f3ca801e = () => interopDefault(import('../docs/pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _c54a9f06 = () => interopDefault(import('../docs/pages/icons/index.vue' /* webpackChunkName: "pages/icons/index" */))
const _d7e9a3ec = () => interopDefault(import('../docs/pages/installation.vue' /* webpackChunkName: "pages/installation" */))
const _05bd21d5 = () => interopDefault(import('../docs/pages/kbreadcrumbs.vue' /* webpackChunkName: "pages/kbreadcrumbs" */))
const _1f785a3d = () => interopDefault(import('../docs/pages/kbutton.vue' /* webpackChunkName: "pages/kbutton" */))
const _0fa8a912 = () => interopDefault(import('../docs/pages/kbuttongroup.vue' /* webpackChunkName: "pages/kbuttongroup" */))
const _0805604a = () => interopDefault(import('../docs/pages/kcard.vue' /* webpackChunkName: "pages/kcard" */))
const _53e46ea1 = () => interopDefault(import('../docs/pages/kcardgrid.vue' /* webpackChunkName: "pages/kcardgrid" */))
const _72c3e4e4 = () => interopDefault(import('../docs/pages/kcheckbox.vue' /* webpackChunkName: "pages/kcheckbox" */))
const _5cbf737d = () => interopDefault(import('../docs/pages/kcircularloader.vue' /* webpackChunkName: "pages/kcircularloader" */))
const _827fd1d8 = () => interopDefault(import('../docs/pages/kdaterange.vue' /* webpackChunkName: "pages/kdaterange" */))
const _e4c62a8a = () => interopDefault(import('../docs/pages/kdropdownmenu.vue' /* webpackChunkName: "pages/kdropdownmenu" */))
const _15235351 = () => interopDefault(import('../docs/pages/kemptyplaceholder.vue' /* webpackChunkName: "pages/kemptyplaceholder" */))
const _61a8f4e0 = () => interopDefault(import('../docs/pages/kexternallink.vue' /* webpackChunkName: "pages/kexternallink" */))
const _57639bff = () => interopDefault(import('../docs/pages/kfixedgrid.vue' /* webpackChunkName: "pages/kfixedgrid" */))
const _1f0b6bdc = () => interopDefault(import('../docs/pages/kfixedgriditem.vue' /* webpackChunkName: "pages/kfixedgriditem" */))
const _7cd601ca = () => interopDefault(import('../docs/pages/kfocustrap.vue' /* webpackChunkName: "pages/kfocustrap" */))
const _0f705511 = () => interopDefault(import('../docs/pages/kgrid.vue' /* webpackChunkName: "pages/kgrid" */))
const _1affabb8 = () => interopDefault(import('../docs/pages/kgriditem.vue' /* webpackChunkName: "pages/kgriditem" */))
const _5f019678 = () => interopDefault(import('../docs/pages/kicon.vue' /* webpackChunkName: "pages/kicon" */))
const _0d41bc14 = () => interopDefault(import('../docs/pages/kiconbutton.vue' /* webpackChunkName: "pages/kiconbutton" */))
const _55a61770 = () => interopDefault(import('../docs/pages/kimg.vue' /* webpackChunkName: "pages/kimg" */))
const _7628ec91 = () => interopDefault(import('../docs/pages/klabeledicon.vue' /* webpackChunkName: "pages/klabeledicon" */))
const _2c9ce57a = () => interopDefault(import('../docs/pages/klinearloader.vue' /* webpackChunkName: "pages/klinearloader" */))
const _46b277b1 = () => interopDefault(import('../docs/pages/klistwithoverflow.vue' /* webpackChunkName: "pages/klistwithoverflow" */))
const _7554d4f6 = () => interopDefault(import('../docs/pages/klogo.vue' /* webpackChunkName: "pages/klogo" */))
const _163caaf2 = () => interopDefault(import('../docs/pages/kmodal.vue' /* webpackChunkName: "pages/kmodal" */))
const _200c6850 = () => interopDefault(import('../docs/pages/koptionaltext.vue' /* webpackChunkName: "pages/koptionaltext" */))
const _22d313d5 = () => interopDefault(import('../docs/pages/koverlay.vue' /* webpackChunkName: "pages/koverlay" */))
const _d8a218d2 = () => interopDefault(import('../docs/pages/kpagecontainer.vue' /* webpackChunkName: "pages/kpagecontainer" */))
const _416e0d72 = () => interopDefault(import('../docs/pages/kradiobutton.vue' /* webpackChunkName: "pages/kradiobutton" */))
const _a688a206 = () => interopDefault(import('../docs/pages/kradiobuttongroup.vue' /* webpackChunkName: "pages/kradiobuttongroup" */))
const _5d178bce = () => interopDefault(import('../docs/pages/krouterlink.vue' /* webpackChunkName: "pages/krouterlink" */))
const _b13519f2 = () => interopDefault(import('../docs/pages/kselect.vue' /* webpackChunkName: "pages/kselect" */))
const _1533cd82 = () => interopDefault(import('../docs/pages/kswitch.vue' /* webpackChunkName: "pages/kswitch" */))
const _1e617393 = () => interopDefault(import('../docs/pages/ktable.vue' /* webpackChunkName: "pages/ktable" */))
const _7111bd6e = () => interopDefault(import('../docs/pages/ktabs.vue' /* webpackChunkName: "pages/ktabs" */))
const _7f5b9267 = () => interopDefault(import('../docs/pages/ktabslist.vue' /* webpackChunkName: "pages/ktabslist" */))
const _5f50328b = () => interopDefault(import('../docs/pages/ktabspanel.vue' /* webpackChunkName: "pages/ktabspanel" */))
const _abcfd3fa = () => interopDefault(import('../docs/pages/ktextbox.vue' /* webpackChunkName: "pages/ktextbox" */))
const _0e74adba = () => interopDefault(import('../docs/pages/ktexttruncator.vue' /* webpackChunkName: "pages/ktexttruncator" */))
const _6d078e08 = () => interopDefault(import('../docs/pages/ktooltip.vue' /* webpackChunkName: "pages/ktooltip" */))
const _3ba3e560 = () => interopDefault(import('../docs/pages/ktransition.vue' /* webpackChunkName: "pages/ktransition" */))
const _70a03ffd = () => interopDefault(import('../docs/pages/layout/index.vue' /* webpackChunkName: "pages/layout/index" */))
const _263472ba = () => interopDefault(import('../docs/pages/loaders/index.vue' /* webpackChunkName: "pages/loaders/index" */))
const _0fdc6bd7 = () => interopDefault(import('../docs/pages/menus/index.vue' /* webpackChunkName: "pages/menus/index" */))
const _609af58e = () => interopDefault(import('../docs/pages/modals/index.vue' /* webpackChunkName: "pages/modals/index" */))
const _53abdfa2 = () => interopDefault(import('../docs/pages/pageheader/index.vue' /* webpackChunkName: "pages/pageheader/index" */))
const _5e7a554e = () => interopDefault(import('../docs/pages/playground/index.vue' /* webpackChunkName: "pages/playground/index" */))
const _7e3655c7 = () => interopDefault(import('../docs/pages/principles.vue' /* webpackChunkName: "pages/principles" */))
const _228dd389 = () => interopDefault(import('../docs/pages/release-process.vue' /* webpackChunkName: "pages/release-process" */))
const _5faf546e = () => interopDefault(import('../docs/pages/snackbars.vue' /* webpackChunkName: "pages/snackbars" */))
const _b51cd6de = () => interopDefault(import('../docs/pages/styling/index.vue' /* webpackChunkName: "pages/styling/index" */))
const _7a6d0d64 = () => interopDefault(import('../docs/pages/tabs.vue' /* webpackChunkName: "pages/tabs" */))
const _15b43730 = () => interopDefault(import('../docs/pages/testing-playground.vue' /* webpackChunkName: "pages/testing-playground" */))
const _bfe9680e = () => interopDefault(import('../docs/pages/textfields/index.vue' /* webpackChunkName: "pages/textfields/index" */))
const _ffc44b18 = () => interopDefault(import('../docs/pages/usekliveregion.vue' /* webpackChunkName: "pages/usekliveregion" */))
const _c2bbfe38 = () => interopDefault(import('../docs/pages/usekresponsiveelement.vue' /* webpackChunkName: "pages/usekresponsiveelement" */))
const _e8e7c190 = () => interopDefault(import('../docs/pages/usekresponsivewindow.vue' /* webpackChunkName: "pages/usekresponsivewindow" */))
const _9fbf55de = () => interopDefault(import('../docs/pages/usekshow.vue' /* webpackChunkName: "pages/usekshow" */))
const _2396903c = () => interopDefault(import('../docs/pages/writing.vue' /* webpackChunkName: "pages/writing" */))
const _404bc09f = () => interopDefault(import('../docs/pages/icons/IconBlock.vue' /* webpackChunkName: "pages/icons/IconBlock" */))
const _d476a140 = () => interopDefault(import('../docs/pages/icons/IconTable.vue' /* webpackChunkName: "pages/icons/IconTable" */))
const _fde73942 = () => interopDefault(import('../docs/pages/playground/cards.vue' /* webpackChunkName: "pages/playground/cards" */))
const _5882319c = () => interopDefault(import('../docs/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _916ccca4,
    name: "accessibility"
  }, {
    path: "/appbars",
    component: _1d3ea704,
    name: "appbars"
  }, {
    path: "/buttons",
    component: _66af7c61,
    name: "buttons"
  }, {
    path: "/colors",
    component: _6fbfe780,
    name: "colors"
  }, {
    path: "/errors",
    component: _642b16fe,
    name: "errors"
  }, {
    path: "/filters",
    component: _8c6cebc4,
    name: "filters"
  }, {
    path: "/glossary",
    component: _f3ca801e,
    name: "glossary"
  }, {
    path: "/icons",
    component: _c54a9f06,
    name: "icons"
  }, {
    path: "/installation",
    component: _d7e9a3ec,
    name: "installation"
  }, {
    path: "/kbreadcrumbs",
    component: _05bd21d5,
    name: "kbreadcrumbs"
  }, {
    path: "/kbutton",
    component: _1f785a3d,
    name: "kbutton"
  }, {
    path: "/kbuttongroup",
    component: _0fa8a912,
    name: "kbuttongroup"
  }, {
    path: "/kcard",
    component: _0805604a,
    name: "kcard"
  }, {
    path: "/kcardgrid",
    component: _53e46ea1,
    name: "kcardgrid"
  }, {
    path: "/kcheckbox",
    component: _72c3e4e4,
    name: "kcheckbox"
  }, {
    path: "/kcircularloader",
    component: _5cbf737d,
    name: "kcircularloader"
  }, {
    path: "/kdaterange",
    component: _827fd1d8,
    name: "kdaterange"
  }, {
    path: "/kdropdownmenu",
    component: _e4c62a8a,
    name: "kdropdownmenu"
  }, {
    path: "/kemptyplaceholder",
    component: _15235351,
    name: "kemptyplaceholder"
  }, {
    path: "/kexternallink",
    component: _61a8f4e0,
    name: "kexternallink"
  }, {
    path: "/kfixedgrid",
    component: _57639bff,
    name: "kfixedgrid"
  }, {
    path: "/kfixedgriditem",
    component: _1f0b6bdc,
    name: "kfixedgriditem"
  }, {
    path: "/kfocustrap",
    component: _7cd601ca,
    name: "kfocustrap"
  }, {
    path: "/kgrid",
    component: _0f705511,
    name: "kgrid"
  }, {
    path: "/kgriditem",
    component: _1affabb8,
    name: "kgriditem"
  }, {
    path: "/kicon",
    component: _5f019678,
    name: "kicon"
  }, {
    path: "/kiconbutton",
    component: _0d41bc14,
    name: "kiconbutton"
  }, {
    path: "/kimg",
    component: _55a61770,
    name: "kimg"
  }, {
    path: "/klabeledicon",
    component: _7628ec91,
    name: "klabeledicon"
  }, {
    path: "/klinearloader",
    component: _2c9ce57a,
    name: "klinearloader"
  }, {
    path: "/klistwithoverflow",
    component: _46b277b1,
    name: "klistwithoverflow"
  }, {
    path: "/klogo",
    component: _7554d4f6,
    name: "klogo"
  }, {
    path: "/kmodal",
    component: _163caaf2,
    name: "kmodal"
  }, {
    path: "/koptionaltext",
    component: _200c6850,
    name: "koptionaltext"
  }, {
    path: "/koverlay",
    component: _22d313d5,
    name: "koverlay"
  }, {
    path: "/kpagecontainer",
    component: _d8a218d2,
    name: "kpagecontainer"
  }, {
    path: "/kradiobutton",
    component: _416e0d72,
    name: "kradiobutton"
  }, {
    path: "/kradiobuttongroup",
    component: _a688a206,
    name: "kradiobuttongroup"
  }, {
    path: "/krouterlink",
    component: _5d178bce,
    name: "krouterlink"
  }, {
    path: "/kselect",
    component: _b13519f2,
    name: "kselect"
  }, {
    path: "/kswitch",
    component: _1533cd82,
    name: "kswitch"
  }, {
    path: "/ktable",
    component: _1e617393,
    name: "ktable"
  }, {
    path: "/ktabs",
    component: _7111bd6e,
    name: "ktabs"
  }, {
    path: "/ktabslist",
    component: _7f5b9267,
    name: "ktabslist"
  }, {
    path: "/ktabspanel",
    component: _5f50328b,
    name: "ktabspanel"
  }, {
    path: "/ktextbox",
    component: _abcfd3fa,
    name: "ktextbox"
  }, {
    path: "/ktexttruncator",
    component: _0e74adba,
    name: "ktexttruncator"
  }, {
    path: "/ktooltip",
    component: _6d078e08,
    name: "ktooltip"
  }, {
    path: "/ktransition",
    component: _3ba3e560,
    name: "ktransition"
  }, {
    path: "/layout",
    component: _70a03ffd,
    name: "layout"
  }, {
    path: "/loaders",
    component: _263472ba,
    name: "loaders"
  }, {
    path: "/menus",
    component: _0fdc6bd7,
    name: "menus"
  }, {
    path: "/modals",
    component: _609af58e,
    name: "modals"
  }, {
    path: "/pageheader",
    component: _53abdfa2,
    name: "pageheader"
  }, {
    path: "/playground",
    component: _5e7a554e,
    name: "playground"
  }, {
    path: "/principles",
    component: _7e3655c7,
    name: "principles"
  }, {
    path: "/release-process",
    component: _228dd389,
    name: "release-process"
  }, {
    path: "/snackbars",
    component: _5faf546e,
    name: "snackbars"
  }, {
    path: "/styling",
    component: _b51cd6de,
    name: "styling"
  }, {
    path: "/tabs",
    component: _7a6d0d64,
    name: "tabs"
  }, {
    path: "/testing-playground",
    component: _15b43730,
    name: "testing-playground"
  }, {
    path: "/textfields",
    component: _bfe9680e,
    name: "textfields"
  }, {
    path: "/usekliveregion",
    component: _ffc44b18,
    name: "usekliveregion"
  }, {
    path: "/usekresponsiveelement",
    component: _c2bbfe38,
    name: "usekresponsiveelement"
  }, {
    path: "/usekresponsivewindow",
    component: _e8e7c190,
    name: "usekresponsivewindow"
  }, {
    path: "/usekshow",
    component: _9fbf55de,
    name: "usekshow"
  }, {
    path: "/writing",
    component: _2396903c,
    name: "writing"
  }, {
    path: "/icons/IconBlock",
    component: _404bc09f,
    name: "icons-IconBlock"
  }, {
    path: "/icons/IconTable",
    component: _d476a140,
    name: "icons-IconTable"
  }, {
    path: "/playground/cards",
    component: _fde73942,
    name: "playground-cards"
  }, {
    path: "/",
    component: _5882319c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
